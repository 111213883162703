// assets
import { RobotOutlined } from "@ant-design/icons";

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const agents = {
  id: "group-agents",
  type: "group",
  children: [
    {
      id: "agents",
      title: "Agents",
      type: "item",
      url: "/agents",
      icon: RobotOutlined,
    },
  ],
};

export default agents;
