import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

import "./index.css";

// scroll bar
import "simplebar/src/simplebar.css";

// apex-chart
import "assets/third-party/apex-chart.css";

// project import
import App from "./App";
import { Environment, getEnvironment } from "utils/environments";
// import reportWebVitals from "reportWebVitals";

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const environment = getEnvironment();
if (environment !== Environment.LOCAL) {
  Sentry.init({
    dsn: "https://b90c8fd41991302ecdebb12f50016050@o4507498102063104.ingest.us.sentry.io/4507498154164224",
    integrations: [],
    environment,
  });
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
