import { MailOutlined } from "@ant-design/icons";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function SupportCard() {
  const handleClick = () => {
    const email = "hey@aigency.ai";
    const subject = encodeURIComponent("Aigency Portal Help & Support");
    window.location.href = `mailto:${email}?subject=${subject}`;
  };

  return (
    <Stack
      display="flex"
      direction="column"
      width="100%"
      padding="20px 20px 30px 20px"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: "#F5F5F5",
        border: "1px solid rgba(37, 59, 115, 0.20)",
        borderRadius: "12px",
      }}
    >
      <Typography variant="h5" fontWeight={700}>
        Help & Support
      </Typography>
      <Typography variant="body2" color="secondary">
        Have any questions?
      </Typography>
      <Button
        onClick={handleClick}
        variant="contained"
        sx={{
          mt: "14px",
          padding: "9px 21px 9px 24px",
          borderRadius: "1000px",
          border: "1px solid #262626",
          backgroundColor: "#262626",
          boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
          "&:hover": {
            backgroundColor: "#262626",
          },
        }}
        startIcon={<MailOutlined />}
      >
        Email us
      </Button>
    </Stack>
  );
}
