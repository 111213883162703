import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { To } from "history";

// material-ui
import { ButtonBase } from "@mui/material";
import { SxProps } from "@mui/material/styles";

// project import
import Logo from "./Logo";
import { APP_DEFAULT_PATH } from "config";
import { activeItem } from "main-store/reducers/menu";

// ==============================|| MAIN LOGO ||============================== //

interface LogoSectionProps {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ sx, to }: LogoSectionProps) => {
  // @ts-ignore
  const { defaultId } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  return (
    <ButtonBase
      disableRipple
      component={Link}
      onClick={() => dispatch(activeItem({ openItem: [defaultId] }))}
      // @ts-ignore
      to={!to ? APP_DEFAULT_PATH : to}
      sx={sx}
    >
      <Logo />
    </ButtonBase>
  );
};

export default LogoSection;
