// assets
import { LockOutlined } from "@ant-design/icons";

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const admin = {
  id: "group-admin",
  type: "group",
  children: [
    {
      id: "admin",
      title: "Admin",
      type: "item",
      url: "/admin/accounts",
      extraUrls: ["/admin/user-agents"],
      icon: LockOutlined,
    },
  ],
  adminOnly: true,
};

export default admin;
