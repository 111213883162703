import { Outlet } from "react-router-dom";

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <>
    {/* the Outlet element is used to determine where child routes of parent routes should be displayed within the parent routes instead of a new page*/}
    <Outlet />
  </>
);

export default MinimalLayout;
