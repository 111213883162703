// material-ui
import { Theme } from "@mui/material/styles";

// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme: Theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: "12px",
          fontWeight: 500,
          padding: "24px 16px",
          "&:not(:last-of-type)": {
            position: "relative",
            "&:after": {
              position: "absolute",
              content: '""',
              backgroundColor: theme.palette.divider,
              width: 2,
              height: "calc(100% - 30px)",
              right: 0,
              top: 15,
            },
          },
        },
        body: {
          fontSize: "14px",
          fontWeight: 400,
          padding: "16px",
        },
      },
    },
  };
}
