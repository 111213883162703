import { ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "hooks/useAuth";

const PrivateRoute = ({ children }: { children: ReactElement }) => {
  const { currentUser } = useAuth();
  let location = useLocation();

  if (!currentUser) {
    // Redirect them to the login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after logging in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
