import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "hooks/useAuth";

const AdminRoute = ({ children }: { children: ReactElement }) => {
  const { isAdmin } = useAuth();

  if (!isAdmin) {
    // Redirect them to the home page.
    return <Navigate to="/" replace />;
  }

  return children;
};

export default AdminRoute;
