// material-ui
import { List, Typography } from "@mui/material";

// project import
import NavItem from "./NavItem";

// types
import { NavItemType } from "types/menu";

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }: { item: NavItemType }) => {
  // @ts-ignore
  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case "collapse":
        return (
          <Typography key={menuItem.id} variant="caption" color="error" sx={{ p: 2.5 }}>
            collapse - only available in paid version
          </Typography>
        );
      case "item":
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography key={menuItem.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return <List sx={{ py: 0, zIndex: 0 }}>{navCollapse}</List>;
};

export default NavGroup;
