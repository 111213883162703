import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

// project import
import Navigation from "./Navigation";
import SupportCard from "components/SupportCard";
import SimpleBar from "components/third-party/SimpleBar";

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  const { drawerOpen } = useSelector((state: any) => state.menu);
  return (
    <>
      <SimpleBar
        sx={{
          "& .simplebar-content": {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Navigation />
      </SimpleBar>
      {drawerOpen && (
        <Box sx={{ p: "24px 16px", mt: "auto" }}>
          <SupportCard />
        </Box>
      )}
    </>
  );
};

export default DrawerContent;
