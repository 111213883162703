import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { signOut } from "firebase/auth";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";

// project import
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import { auth } from "firebase-config";
import useUserData from "hooks/useUserData";
import { logError, logInfo } from "utils/logging";
import ProfileTab from "./ProfileTab";

// assets
import { LogoutOutlined } from "@ant-design/icons";

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const anchorRef = useRef<any>(null);
  const { userProfile } = useUserData();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      logInfo("User signed out successfully");
      Sentry.setUser(null);
      navigate("/auth/login");
    } catch (error: any) {
      logError("Sign out error", error.message);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = "grey.300";

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : "transparent",
          borderRadius: 1,
          "&:hover": { bgcolor: "secondary.lighter" },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={1.5} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar alt="profile user" src={userProfile?.avatar} sx={{ width: 32, height: 32 }} />
          <Typography variant="subtitle1" sx={{ pt: 0.5 }}>
            {userProfile?.firstName} {userProfile?.lastName}
          </Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          // @ts-ignore
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  // @ts-ignore
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down("md")]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  {/* @ts-ignore */}
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Stack direction="row" spacing={1.25} alignItems="center">
                            <Avatar
                              alt="profile user"
                              src={userProfile?.avatar}
                              sx={{ width: 32, height: 32 }}
                            />
                            <Stack alignItems="center">
                              <Typography variant="h6" sx={{ mt: 0.5 }}>
                                {userProfile?.firstName} {userProfile?.lastName}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item>
                          <IconButton size="large" color="secondary" onClick={handleLogout}>
                            <LogoutOutlined />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {open && <ProfileTab handleLogout={handleLogout} />}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
