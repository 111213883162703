// material-ui
import { useTheme } from "@mui/material/styles";
import { Stack } from "@mui/material";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
import Logo from "components/Logo";

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }: { open: boolean }) => {
  const theme = useTheme();

  return (
    // only available in paid version
    <DrawerHeaderStyled
      theme={theme}
      // @ts-ignore
      open={open}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Logo />
      </Stack>
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
