import { NavItemType } from "types/menu";
import admin from "./admin";
import agents from "./agents";
import dashboard from "./dashboard";
import support from "./support";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [dashboard, agents, support, admin],
};

export default menuItems;
