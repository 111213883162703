import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MinimalLayout from "layouts/MinimalLayout";

// render - login
const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")));
const AuthForgotPassword = Loadable(lazy(() => import("pages/authentication/ForgotPassword")));
const AuthCheckMail = Loadable(lazy(() => import("pages/authentication/CheckMail")));
const AuthActionHandler = Loadable(lazy(() => import("pages/authentication/ActionHandler")));
// const AuthRegister = Loadable(
//   lazy(() => import("pages/authentication/Register"))
// );

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/auth",
  element: <MinimalLayout />,
  children: [
    {
      path: "login",
      element: <AuthLogin />,
    },
    {
      path: "forgot-password",
      element: <AuthForgotPassword />,
    },
    {
      path: "check-mail",
      element: <AuthCheckMail />,
    },
    // TODO
    // {
    //   path: 'register',
    //   element: <AuthRegister />
    // },
    {
      path: "action",
      element: <AuthActionHandler />,
    },
  ],
};

export default LoginRoutes;
