import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import ScrollTop from "components/ScrollTop";
import { AuthProvider } from "contexts/AuthContext";
import { UserDataProvider } from "contexts/UserDataContext";
import { UserAgentsProvider } from "contexts/AIUserAgentsContext";
import { store } from "main-store";
import Routes from "routes";
import ThemeCustomization from "themes";

// ==============================|| APP ||============================== //

const App = () => (
  <ReduxProvider store={store}>
    <AuthProvider>
      <UserDataProvider>
        <UserAgentsProvider>
          <Router>
            <ThemeCustomization>
              <ScrollTop>
                <Routes />
              </ScrollTop>
            </ThemeCustomization>
          </Router>
        </UserAgentsProvider>
      </UserDataProvider>
    </AuthProvider>
  </ReduxProvider>
);

export default App;
