import { createContext, useEffect, useState, ReactNode } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { apiDb, functions } from "firebase-config";
import { logError } from "utils/logging";
import { httpsCallable } from "firebase/functions";
import { UserAgent } from "types/user-agent";

type UserAgentsContextType = {
  userAgents: UserAgent[];
  addUserAgent: (name: string, color: string) => Promise<void>;
  deleteUserAgent: (id: string) => Promise<void>;
  updateUserAgent: (id: string, newData: Partial<UserAgent>) => Promise<void>;
};

export const UserAgentsContext = createContext<UserAgentsContextType | undefined>(undefined);

export const UserAgentsProvider = ({ children }: { children: ReactNode }) => {
  const [userAgents, setUserAgents] = useState<UserAgent[]>([]);
  const manageAIUserAgent = httpsCallable(functions, "manageAIUserAgent");

  useEffect(() => {
    // We're going to read straight from the api db but modify the agents using server side code.
    // Couldn't set good enough firestore rules in the api project, to allow write operations only for admins.
    const unsubscribe = onSnapshot(collection(apiDb, "aiUserAgents"), (snapshot) => {
      const agents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as UserAgent[];
      const sortedAgents = agents.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      setUserAgents(sortedAgents);
    });

    return unsubscribe;
  }, []);

  const addUserAgent = async (name: string, color: string) => {
    const newAgent = { name, color };
    try {
      await manageAIUserAgent({ operation: "add", userAgentData: newAgent });
    } catch (error: any) {
      logError("Error adding user agent", error.message);
    }
  };

  const deleteUserAgent = async (id: string) => {
    try {
      await manageAIUserAgent({ operation: "delete", userAgentId: id });
    } catch (error: any) {
      logError("Error deleting user agent", error.message);
    }
  };

  const updateUserAgent = async (id: string, newData: Partial<UserAgent>) => {
    try {
      await manageAIUserAgent({ operation: "update", userAgentId: id, userAgentData: newData });
    } catch (error: any) {
      logError("Error updating user agent", error.message);
    }
  };

  return (
    <UserAgentsContext.Provider
      value={{ userAgents, addUserAgent, deleteUserAgent, updateUserAgent }}
    >
      {children}
    </UserAgentsContext.Provider>
  );
};
