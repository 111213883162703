import { FileTextOutlined, SettingOutlined } from "@ant-design/icons";

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const support = {
  id: "support",
  type: "group",
  children: [
    {
      id: "documentation",
      title: "Dev & Documentation",
      type: "item",
      url: "/documentation",
      icon: FileTextOutlined,
    },
    {
      id: "account-settings",
      title: "Account Settings",
      type: "item",
      url: "/account/settings/personal",
      extraUrls: [
        "/account/settings/email",
        "/account/settings/password",
        "/account/settings/settings",
      ],
      icon: SettingOutlined,
    },
  ],
};

export default support;
