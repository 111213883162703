import { createContext, useEffect, useState } from "react";

import { doc, onSnapshot } from "firebase/firestore";
import { db } from "firebase-config";
import useAuth from "hooks/useAuth";
import { UserProfile } from "types/user";
import { logError } from "utils/logging";

type UserDataContextType = {
  userProfile: UserProfile | null;
};

export const UserDataContext = createContext<UserDataContextType>({
  userProfile: null,
});

export const UserDataProvider = ({ children }: { children: React.ReactElement }) => {
  const { currentUser } = useAuth();
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    if (currentUser) {
      const userRef = doc(db, "users", currentUser.uid);
      const unsubscribe = onSnapshot(
        userRef,
        (doc) => {
          if (doc.exists()) {
            setUserProfile(doc.data() as UserProfile);
          } else {
            // Handle the case where there is no data
            setUserProfile(null);
          }
        },
        (error: any) => {
          logError("Error fetching user data", error.message);
          setUserProfile(null);
        }
      );

      return unsubscribe;
    }
  }, [currentUser]);

  return <UserDataContext.Provider value={{ userProfile }}>{children}</UserDataContext.Provider>;
};
