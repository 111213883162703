import { createContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { auth } from "../firebase-config";
import { User, onIdTokenChanged } from "firebase/auth";

type AuthContextType = {
  currentUser: User | null;
  isAdmin: boolean;
};

export const AuthContext = createContext<AuthContextType>({ currentUser: null, isAdmin: false });

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkAdmin = async (user: User | null) => {
      if (user) {
        const tokenResult = await user.getIdTokenResult();
        setIsAdmin(tokenResult.claims.admin === true);
      } else {
        setIsAdmin(false);
      }
    };

    // onIdTokenChanged will listen to auth changes log-in/log-out and email/password changes etc.
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      setCurrentUser(user);
      await checkAdmin(user);
      Sentry.setUser({ id: user?.uid });
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, isAdmin }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
